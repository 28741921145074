import React, { useEffect, useState, useRef } from 'react'
import {
    TextInput,
    SelectInput,
    useDataProvider,
    useGetList,
    useUpdate,
    useNotify,
    ListContextProvider,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
} from 'react-admin'
import { Divider, Box, Button, Grid, IconButton } from '@mui/material'
import { get, isEmpty } from 'lodash-es'
import {
    useForm,
    FormProvider,
    useFormContext,
    useFieldArray,
} from 'react-hook-form'
import { CustomLabel } from '../../CustomLabel'
import ConnectForm from '../../Auth/ConnectForm'
import SyncButton from '../../SyncButton'
import { useListController } from 'ra-core'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../appConfigs'
import ListPagination from '../List/ListPagination'
import { TenantShowFilter } from '../tenants/TenantShow'
import { EmptyState } from '../../react_admin/EmptyState'
import { RunPhaseField } from '../../common'
import LinkField from '../../LinkField'
import PandiForm from '../themeConfig/PandiForm'
import { PasswordInput } from 'ra-ui-materialui'
import RemoveCircleOutlined from '@mui/icons-material/RemoveCircleOutlined'
import { required } from '../../inputValidators'

export const SourceControlJobDataGrid = ({ ...props }) => (
    <>
        <Datagrid
            empty={<EmptyState emptyStateText={'No activity yet.'} />}
            rowClick={'show'}
            {...props}
            sx={{
                '& .RaDatagrid-tableWrapper': {
                    marginTop: '20px',
                    border: '1px solid #DBDEE5',
                    boxShadow: 'none',
                },
            }}
            resource={'runs'}
            bulkActionButtons={false}
        >
            <TextField source="id" label={'ID'} sortable={false} />
            <FunctionField
                label={'INTEGRATION'}
                sortable={false}
                render={(record) =>
                    record.trigger !== 'webhook'
                        ? record.jobSpec?.tenant?.configs?.force_build
                        : 'See Logs'
                }
            />
            <FunctionField
                label={'RELEASE TAG'}
                sortable={false}
                render={(record) =>
                    record.trigger !== 'webhook'
                        ? record.jobSpec?.tenant?.configs?.tag_name
                        : 'See Logs'
                }
            />
            <TextField source="trigger" label={'TRIGGER'} sortable={false} />
            <DateField source="startedDate" label="STARTED" showTime />
            <DateField source="completedDate" label="COMPLETED" showTime />
            <RunPhaseField source="status.phase" label="STATUS" />
            <LinkField
                className="detailLink"
                basePath="runs"
                redirect="show"
                sortable={true}
                variant="outlined"
                text="SHOW LOGS"
            />
        </Datagrid>
    </>
)

const SourceControlJobList = ({ tenant }) => {
    const controllerProps = useListController({
        perPage: SUBGRID_RECORDS_PER_PAGE,
        bulkActionButtons: false,
        sort: { field: 'createdDate', order: 'DESC' },
        filter: { tenantId: tenant.id },
        actions: null,
        basePath: '/runs',
        resource: 'runs',
        disableSyncWithLocation: true,
        storeKey: 'subList',
    })

    //after the component mounts, refetch the runs every time the tenant's lastRun changes.
    //this is dependent on the parent component polling the tenant.
    const mounted = useRef(false)
    useEffect(() => {
        if (mounted.current) {
            controllerProps.refetch()
        } else {
            mounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant?.status?.lastRun, tenant?.status?.currentRun])

    return (
        <ListContextProvider value={controllerProps}>
            <TenantShowFilter label={'SOURCE CONTROL ACTIVITY'} />
            <SourceControlJobDataGrid />
            <ListPagination />
        </ListContextProvider>
    )
}

export const getRepoType = (integration) => {
    const repoURL = integration?.repositoryUrl
    if (repoURL.includes('github')) {
        return 'github'
    } else if (repoURL.includes('gitlab')) {
        return 'gitlab'
    } else if (repoURL.includes('bitbucket')) {
        return 'bitbucket'
    } else if (repoURL.includes('azure')) {
        return 'azure-devops'
    } else {
        //TODO: what do we do if it's a random string?
        return 'github'
    }
}

const SourceControlForm = ({ tenant }) => {
    const dataProvider = useDataProvider()
    const methods = useForm({
        defaultValues: {},
    })
    const [sourceControlTenant, setSourceControlTenant] = useState(tenant)
    const [repoType, setRepoType] = useState('github')
    const { data: integrations, isLoading } = useGetList('integrations', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'longName', order: 'ASC' },
        filter: { type: 'INTERNAL', repository_url__not_empty: true },
    })

    const tenantCallback = (event) => {
        setSourceControlTenant(event.payload)
    }

    useEffect(() => {
        dataProvider.subscribe(
            `tenants/${sourceControlTenant.id}`,
            tenantCallback
        )
        return () =>
            dataProvider.unsubscribe(
                `tenants/${sourceControlTenant.id}`,
                tenantCallback
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [update] = useUpdate('tenants', {
        id: sourceControlTenant.id,
        data: {
            ...sourceControlTenant,
            configs: {
                ...sourceControlTenant?.configs,
                force_build: methods.getValues()['buildIntegration'],
                force_build_id: methods.getValues()['buildIntegrationID'],
                tag_name: methods.getValues()['releaseTag'],
            },
        },
        previousData: sourceControlTenant,
    })

    return (
        !isLoading && (
            <Box sx={{ margin: '20px' }}>
                <CustomLabel
                    title="CONNECT SOURCE CONTROL"
                    subtitle="Connect any repository type that your integrations need"
                />
                <ConnectForm
                    tenant={sourceControlTenant}
                    pollTenant={false}
                    autoRedirect={true}
                />
                <div />
                <Divider sx={{ margin: '30px 0' }} />
                <BuildSecretsForm sourceControlTenant={sourceControlTenant} />
                <Divider sx={{ margin: '30px 0' }} />
                <WebhooksConfigForm sourceControlTenant={sourceControlTenant} />
                <Divider sx={{ margin: '30px 0' }} />
                <Box sx={{ margin: '0 15px' }}>
                    <CustomLabel
                        title="MANUAL BUILD"
                        subtitle="Choose an integration from the drop down and pick a tag to run a build manually."
                    />
                    <FormProvider
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        {...methods}
                    >
                        <>
                            <SelectInput
                                helperText="Select an integration -
                             note: Only integrations with repository urls set will appear"
                                fullWidth={true}
                                label="Integration To Build"
                                source={'buildIntegration'}
                                choices={integrations}
                                validate={required}
                                translateChoice={false}
                                optionText={(integration) =>
                                    `${integration.id} - ${integration.longName}`
                                }
                                optionValue="name"
                                InputProps={{ disableUnderline: true }}
                                sx={{
                                    width: '328px',
                                    margin: '0',
                                    display: 'flex',
                                }}
                                onChange={(event) => {
                                    const integration = integrations.find(
                                        (integration) =>
                                            integration.name ===
                                            event.target.value
                                    )
                                    setRepoType(getRepoType(integration))
                                    methods.setValue(
                                        'releaseTag',
                                        integration.repositoryTrackingBranch
                                    )
                                    methods.setValue(
                                        'buildIntegrationID',
                                        integration.id
                                    )
                                }}
                            />
                            <TextInput
                                helperText="Set a tag that will be used to identify this release"
                                validate={required}
                                label="Release Tag"
                                source="releaseTag"
                                variant="filled"
                                sx={{
                                    width: '328px',
                                    margin: '0',
                                    display: 'flex',
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </>
                    </FormProvider>
                    <SyncButton
                        key="sync"
                        label="BUILD RELEASE"
                        record={sourceControlTenant}
                        disabled={
                            !get(
                                sourceControlTenant,
                                'status.auth.connected',
                                false
                            ) || !methods.formState.isValid
                        }
                        onClick={update}
                        buttonStyles={{
                            fontFamily: 'RobotoCondensedBold',
                            color: 'white',
                            width: '200px',
                            height: '42px',
                            margin: '15px 15px 0 0',
                            backgroundColor: '#626FFC',
                            fontSize: '15px',
                            lineHeight: '21px',
                            letterSpacing: '2px',
                            borderRadius: '0',
                            alignSelf: 'center',
                            '&:hover:hover': {
                                backgroundColor: '#626FFC',
                            },
                        }}
                        syncType={'normal'}
                        payload={{
                            repoType: repoType,
                            buildIntegration: methods.getValues()[
                                'buildIntegration'
                            ],
                        }}
                    />
                    <Divider sx={{ margin: '30px 0' }} />
                    <SourceControlJobList tenant={sourceControlTenant} />
                </Box>
            </Box>
        )
    )
}

const SourceControlFormWrapper = ({ orgID }) => {
    const dataProvider = useDataProvider()
    const [sourceControlTenant, setSourceControlTenant] = useState({})
    useEffect(() => {
        if (orgID && isEmpty(sourceControlTenant)) {
            dataProvider
                .SOURCE_CONTROL_TENANT('organizations', { id: orgID })
                .then(({ data }) => {
                    setSourceControlTenant(data)
                })
        }
    }, [dataProvider, orgID, sourceControlTenant])

    return (
        !isEmpty(sourceControlTenant) && (
            <SourceControlForm tenant={sourceControlTenant} />
        )
    )
}

export default SourceControlFormWrapper

const buildSecretsStyles = {
    actionButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '10xp',
    },
    addButton: {
        color: '#626FFC',
        width: '120px',
        height: '40px',
        backgroundColor: 'white',
        fontSize: '16px',
        borderRadius: '0',
        margin: '0 5px 0 5px',
        '&:disabled': {
            background: 'transparent',
        },
        boxShadow: 'none',
    },
    saveButton: {
        width: '165px',
        height: '42px',
        margin: '0 5px 0 5px',
    },
}

const BuildSecretsComponent = ({
    configs,
    existingTokens,
    integrationName,
    setExistingTokens,
    source,
    tenantId,
}) => {
    const formMethods = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control: formMethods.control,
        name: source,
    })
    const existingTokenKeys =
        existingTokens?.build_tokens?.map((item) => {
            return item.key
        }) ?? []
    const fullRemove = (key, index) => {
        setExistingTokens({
            build_tokens: existingTokens?.build_tokens?.filter(
                (item) => item.key !== key
            ),
        })
        remove(index)
    }
    const dataProvider = useDataProvider()
    const notify = useNotify()

    const update = () => {
        dataProvider
            .UPDATE_BUILD_TOKENS('tenants', {
                tenantId: tenantId,
                data: {
                    build_tokens: JSON.stringify(
                        formMethods.getValues()?.build_tokens
                    ),
                    configs: configs,
                    integration_name: integrationName,
                },
            })
            .then((res) => {
                notify('Updated Build Environment Secrets!')
                formMethods.reset(formMethods.getValues())
            })
            .catch(() => notify('Error Updating Build Environment Secrets'))
    }

    return (
        <Grid item xs={12} container spacing={2}>
            {fields.map((field, index) => (
                <Grid key={field.id} item xs={12} container spacing={0}>
                    <Grid item xs={3}>
                        <TextInput
                            validate={[required]}
                            sx={{
                                '& input': {
                                    backgroundColor: 'white',
                                },
                            }}
                            source={`${source}[${index}].key`}
                            label={'Key'}
                            helperText={''}
                            disabled={existingTokenKeys.includes(field.key)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {existingTokenKeys.includes(field.key) ? (
                            <TextInput
                                validate={[required]}
                                sx={{
                                    '& input': {
                                        backgroundColor: 'white',
                                    },
                                }}
                                source={`${source}[${index}].value`}
                                label={'value'}
                                helperText={''}
                                disabled={true}
                                type={'password'}
                            />
                        ) : (
                            <PasswordInput
                                validate={[required]}
                                sx={{
                                    '& input, & div': {
                                        backgroundColor: 'white',
                                    },
                                }}
                                source={`${source}[${index}].value`}
                                label={'Secret'}
                                helperText={''}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <IconButton
                            color="error"
                            onClick={() => fullRemove(field.key, index)}
                            sx={{ marginTop: '12px' }}
                        >
                            <RemoveCircleOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Box sx={buildSecretsStyles.actionButtonsWrapper}>
                <Button
                    variant="outlined"
                    sx={buildSecretsStyles.addButton}
                    onClick={() => append({ key: '', value: '' })}
                >
                    Add
                </Button>
                {formMethods.formState.isDirty && (
                    <Button
                        variant="outlined"
                        sx={buildSecretsStyles.addButton}
                        onClick={() => {
                            formMethods.reset()
                            setExistingTokens({
                                build_tokens: formMethods.getValues()
                                    ?.build_tokens,
                            })
                        }}
                    >
                        Reset
                    </Button>
                )}
                <Button
                    variant={'text'}
                    className={'filledButton'}
                    sx={buildSecretsStyles.saveButton}
                    disabled={
                        !formMethods.formState.isDirty ||
                        !formMethods.formState.isValid
                    }
                    onClick={() => {
                        update()
                    }}
                >
                    SAVE CHANGES
                </Button>
            </Box>
        </Grid>
    )
}

const BuildSecretsForm = ({ sourceControlTenant }) => {
    const [existingTokens, setExistingTokens] = useState({
        build_tokens: sourceControlTenant?.configs?.build_tokens?.map((key) => {
            return { key: key, value: 'placeholder' }
        }),
    })

    // This is used to update the existing tokens after a save so they are treated as existing values
    useEffect(() => {
        setExistingTokens({
            build_tokens: sourceControlTenant?.configs?.build_tokens?.map(
                (key) => {
                    return { key: key, value: 'placeholder' }
                }
            ),
        })
    }, [sourceControlTenant?.configs?.build_tokens])

    const schema = [
        {
            title: 'BUILD ENVIRONMENT SECRETS',
            subheading:
                'Add or remove any key value pairs needed for build environment secrets.',
            divider: false,
            docLink:
                'https://docs.pandium.com/integration-hub/setting-up-source-control',
            fields: {
                build_tokens: {
                    type: 'custom',
                    component: () => (
                        <BuildSecretsComponent
                            source={'build_tokens'}
                            existingTokens={existingTokens}
                            integrationName={
                                sourceControlTenant.integration?.name
                            }
                            setExistingTokens={setExistingTokens}
                            configs={sourceControlTenant.configs}
                            tenantId={sourceControlTenant.id}
                        />
                    ),
                },
            },
        },
    ]
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: existingTokens,
    })
    return (
        <FormProvider {...methods}>
            <PandiForm schema={schema} values={existingTokens} />
        </FormProvider>
    )
}

const webhooksConfigSchema = (sourceControlTenant) => [
    {
        title: 'Webhooks Configuration',
        subheading: 'Configure the source control webhook subscriptions.',
        divider: false,
        fields: {
            enable_webhooks: {
                type: 'boolean',
                label: 'Enable Webhooks',
            },
        },
    },
]

const webhooksConfigFormStyles = {
    updateButton: {
        width: '165px',
        height: '42px',
        margin: '15px',
    },
}

const WebhooksConfigForm = ({ sourceControlTenant }) => {
    const methods = useForm({
        defaultValues: {
            enable_webhooks:
                sourceControlTenant?.configs?.enable_webhooks || false,
        },
    })

    const notify = useNotify()
    const [update] = useUpdate()
    const dataProvider = useDataProvider()

    // Function to be called when the SyncButton is clicked
    const handleUpdate = async () => {
        const enable_webhooks = methods.getValues()['enable_webhooks']
        const params = {
            id: sourceControlTenant.id,
            data: {
                ...sourceControlTenant,
                configs: {
                    ...sourceControlTenant.configs,
                    enable_webhooks: enable_webhooks,
                },
            },
            previousData: sourceControlTenant,
        }

        try {
            await update('tenants', params, {
                onSuccess: (data) => {
                    dataProvider
                        .SYNC_INITIATED('runs', {
                            data: {
                                tenantId: sourceControlTenant.id,
                                mode: 'init',
                            },
                        })
                        .then((res) => {
                            if (res.data) {
                                notify('Updating...')
                            }
                        })
                },
                onError: (error) => {
                    notify('Failed to update Source Control tenant...')
                },
            })
        } catch (error) {
            console.error('Failed to update:', error)
        }
    }

    return (
        <FormProvider {...methods}>
            <form>
                <PandiForm schema={webhooksConfigSchema(sourceControlTenant)} />
                <Button
                    variant={'text'}
                    className={'filledButton'}
                    sx={webhooksConfigFormStyles.updateButton}
                    disabled={!methods.formState.isDirty}
                    onClick={handleUpdate}
                >
                    UPDATE
                </Button>
            </form>
        </FormProvider>
    )
}
