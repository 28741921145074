import React from 'react'
import {
    Admin,
    fetchUtils,
    localStorageStore,
    CustomRoutes,
    Notification,
} from 'react-admin'
import { restclient, authProvider } from '@thefront/pandipackV2'
import ReactGA from 'react-ga'
import MyLayout from './components/react_admin/Layout'
import StylesProvider from '@mui/styles/StylesProvider'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import SettingsTabPage from './components/react_admin/Settings'
import MetricsPage from './components/react_admin/Metrics'
import NativeAppsPage from './components/react_admin/NativeApps'
import { Route } from 'react-router-dom'
import { orgReducer } from './customReducers'
import { Provider } from 'react-redux'
import Login from './components/Login'
import AccountShow from './components/resources/accounts/AccountShow'

import { GA_DEBUG, GA_CODE, API_URI, Resources, muiV5Theme } from './appConfigs'
import ScrollToTop from './ScrollToTop'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'
import rootSaga from './customSagas'
import { BrowserRouter } from 'react-router-dom'
import i18nProvider from '@thefront/pandipackV2'

// Prevent className collision in production
const generateClassName = createGenerateClassName({
    productionPrefix: 'prod',
})

const store = localStorageStore()
store.setItem('sidebar.open', true)

const raTheme = {
    ...muiV5Theme,
    components: {
        ...muiV5Theme.components,
        RaCreate: {
            styleOverrides: {
                root: {
                    margin: 0,
                    '& .RaCreate-main': {
                        margin: '20px 0 0 0',
                    },
                    '& .RaCreate-card': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    marginTop: '20px',
                    '& .RaBulkActionsToolbar-toolbar': {
                        backgroundColor: '#F1F4FB',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .RaBulkActionsToolbar-collapsed': {
                        marginTop: '0',
                    },
                },
            },
        },
        RaTopToolbar: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0 17px 0 0',
                    '& h1': {
                        fontFamily: 'RobotoCondensedBold',
                        margin: '0px 0px 0px 0px',
                        fontSize: '34px',
                    },
                    '& .createButton': {
                        fontFamily: 'RobotoCondensedBold',
                        fontSize: '15px',
                        color: '#FFFFFF',
                        letterSpacing: '2px',
                        textAlign: 'center',
                        lineHeight: '21px',
                        width: '165px',
                        height: '42px',
                        backgroundColor: '#626FFC',
                        borderRadius: '0',
                    },
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-row': {
                        '&:hover:hover': {
                            '& td': {
                                '& .detailLink': {
                                    color: '#626FFC',
                                    letterSpacing: '1.5px',
                                    fontFamily: 'RobotoCondensedBold',
                                    textDecoration: 'none',
                                },
                            },
                        },
                        '& td': {
                            '& .detailLink': {
                                color: 'white',
                                letterSpacing: '1.5px',
                                fontFamily: 'RobotoCondensedBold',
                                textDecoration: 'none',
                            },
                        },
                    },
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: '#F1F4FB',
                        fontFamily: 'RobotoCondensedBold',
                        height: '54px',
                        fontSize: '17px',
                        marginTop: '20px',
                        zIndex: '0',
                        '& svg': {
                            color: '#626FFC',
                        },
                    },
                    '& .RaDatagrid-rowCell': {
                        maxWidth: '15%',
                        height: '60px',
                    },
                    '& .RaDatagrid-checkbox': {
                        '& svg': {
                            color: '#626FFC',
                        },
                    },
                    '& .RaDatagrid-table': {
                        boxShadow:
                            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                    },
                },
            },
        },
        RaRichTextInput: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    '& .RaRichTextInput-editorContent': {
                        '& .ProseMirror': {
                            backgroundColor: '#0000000a',
                            border: 'none',
                            minHeight: '200px',
                            minWidth: '300px',
                        },
                        '& .ProseMirror:focus': {
                            backgroundColor: '#0000000a',
                            border: 'none',
                            outline: 'none',
                        },
                    },
                },
            },
        },
        RaSimpleFormIterator: {
            styleOverrides: {
                root: {
                    '& .RaSimpleFormIterator-list': { width: '100%' },
                    '& .RaSimpleFormIterator-line': {
                        margin: '10px 0px',
                        width: '115%',
                    },
                    '& .RaSimpleFormIterator-form': { width: '100%' },
                    '& .RaSimpleFormIterator-action': { visibility: 'visible' },
                },
            },
        },
        RaList: {
            styleOverrides: {
                root: {
                    margin: '6px 0 0 0',
                    backgroundColor: 'white',
                    '& .RaList-content': {
                        fontSize: '5px',
                        boxShadow: 'none',
                    },
                    '& .RaList-actions': {
                        backgroundColor: 'white',
                    },
                },
            },
        },
    },
}

const reducer = combineReducers({
    org: orgReducer,
})

const sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware]

const reduxStore = configureStore({
    reducer,
    devTools: true,
    middleware,
})

sagaMiddleware.run(rootSaga)

export default ({ keycloak }) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.initialize(GA_CODE, { debug: GA_DEBUG })
    }
    const keycloakClient = authProvider(keycloak)

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' })
        }
        options.headers.set('Authorization', `Bearer ${keycloak.token}`)
        options.headers.set('Admin', true)
        return fetchUtils.fetchJson(url, options)
    }

    const username = keycloak.tokenParsed.preferred_username
    const userId = keycloak.tokenParsed.user_id
    const orgPath = keycloak.tokenParsed.user_group[0].split('/')

    const isSuperUser = orgPath.includes('pandium')
    const isParentOrg = !orgPath.includes('partners') || isSuperUser

    // All organization info comes from keycloak in the form <id>--<name>,
    // but we look in different places for parent orgs, parnter orgs, and
    // the current account of a super user
    const myOrgWithId = isSuperUser
        ? keycloak.tokenParsed.account
        : isParentOrg
        ? orgPath[1]
        : orgPath[orgPath.length - 1]

    // in the case of a super user with no account set set on their
    // token, we start out with these variables set to null.
    let orgId, parentId, orgName, parentOrgName
    if (myOrgWithId) {
        const parentOrgWithId = isParentOrg ? myOrgWithId : orgPath[1]
        orgId = myOrgWithId.split('--')[0]
        orgName = myOrgWithId.split('--')[1]
        parentId = parentOrgWithId.split('--')[0]
        parentOrgName = parentOrgWithId.split('--')[1]
    }

    const namespace = API_URI.includes('pandium.io')
        ? `prod-${parentOrgName}`
        : `staging-${parentOrgName}`

    const orgApprovalStatus = keycloak.tokenParsed.approval_status

    console.debug('NAMESPACE: ', namespace)

    // Pendo is product analytics tracking and onboarding software
    // to add pendo to other enviroments, update this condition and add approptiate tags
    if (
        API_URI.includes('sandbox') ||
        API_URI.includes('demo') ||
        API_URI.includes('pandium.io')
    ) {
        const prefix = API_URI.includes('sandbox')
            ? 'sandbox-'
            : API_URI.includes('demo')
            ? 'demo-'
            : 'prod-'
        window.pendo.initialize({
            visitor: {
                id: prefix + keycloak.tokenParsed.email,
                keycloak_id: userId,
                email: keycloak.tokenParsed.email,
                full_name: `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`,
                role: isSuperUser
                    ? 'super user'
                    : orgId === parentId
                    ? 'customer'
                    : 'partner',
            },
            account: {
                id: prefix + namespace,
                org_name: orgName,
            },
        })
    }

    // We can customize notifications here
    // See https://marmelab.com/react-admin/Theming.html#notifications
    const MyNotification = (props) => <Notification {...props} />

    const LayoutWithUsername = (props) => {
        return (
            <MyLayout
                username={username}
                userId={userId}
                orgId={orgId}
                isParentOrg={isParentOrg}
                parentId={parentId}
                parentOrgName={parentOrgName}
                isSuperUser={isSuperUser}
                orgApprovalStatus={orgApprovalStatus}
                {...props}
            />
        )
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={raTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Provider store={reduxStore}>
                            <BrowserRouter>
                                <Admin
                                    disableTelemetry
                                    authProvider={keycloakClient}
                                    dataProvider={restclient(
                                        API_URI,
                                        httpClient
                                    )}
                                    i18nProvider={i18nProvider}
                                    loginPage={Login} //custom login page (currently blank)
                                    layout={LayoutWithUsername}
                                    theme={raTheme}
                                    title=""
                                    notification={MyNotification}
                                    store={store} //This is needed for sidebar open state
                                >
                                    <CustomRoutes>
                                        {isParentOrg && (
                                            <Route
                                                path="/settings"
                                                element={<SettingsTabPage />}
                                            />
                                        )}
                                        {isParentOrg && (
                                            <Route
                                                path="/metrics"
                                                element={<MetricsPage />}
                                            />
                                        )}
                                        {isParentOrg && (
                                            <Route
                                                path="/nativeapps"
                                                element={<NativeAppsPage />}
                                            />
                                        )}
                                        <Route
                                            path="/account"
                                            element={
                                                <AccountShow
                                                    namespace={namespace}
                                                    userId={userId}
                                                    id={orgId}
                                                />
                                            }
                                        />
                                    </CustomRoutes>
                                    {Resources(
                                        namespace,
                                        isParentOrg,
                                        parentOrgName,
                                        orgApprovalStatus,
                                        userId
                                    )}
                                    <ScrollToTop />
                                </Admin>
                            </BrowserRouter>
                        </Provider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    )
}
